<template>
  <div class="auth-wrapper auth-v1 px-4">
    <div class="auth-inner py-2">
      <b-card class="mb-0 p-2 pt-3 pb-3">

        <!-- logo -->
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            {{ getAppName() }}
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          {{ $i18n.t('create_team_page.title') }}
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $i18n.t('create_team_page.message') }}
        </b-card-text>

        <validation-observer
            ref="refFormObserver"
        >
          <!-- email -->
          <b-form-group
              :label="$i18n.t('Name')"
              label-for="name"
          >
            <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
            >
              <b-form-input
                  id="name"
                  v-model="name"
                  :state="errors.length > 0 ? false:null"
                  name="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <colors :selected-color.sync="color" />

          <b-form-group
              :label="$i18n.t('admin.category.categories')"
              label-for="categories"
          >
            <validation-provider
                #default="{ errors }"
                name="categories"
                rules="required"
            >
              <v-select
                  v-model="selectedCategories"
                  :options="categoriesTemplates"
                  :reduce="item => item.id"
                  label="name"
                  multiple
                  name="categories"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <small class="text-danger">{{ $i18n.t('user.settings.teams.categoryNote') }}</small>
          <!-- button -->
          <b-button
              block
              class="mt-1"
              variant="primary"
              @click="save"
          >
            {{ $i18n.t('create_team_page.button') }}
            <b-spinner
                v-if="isLoading"
                small
            />
          </b-button>

          <p class="text-center mt-2">
            <b-button
                block
                variant="link"
                @click="logout"
            >
              <feather-icon icon="ChevronLeftIcon" />
              {{ $i18n.t('Logout') }}
            </b-button>
          </p>
        </validation-observer>
      </b-card>
    </div>
  </div>

</template>

<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {required} from '@validations'
import RepositoryFactory from "@/repositories/RepositoryFactory";
import Colors from "@/components/common/colors.vue"

export default {
  components: {
    Colors
  },
  data() {
    return {
      isLoading: false,
      name: '',
      color: '#90CDF4',
      required,
      selectedCategories: [],
      categoriesTemplates: [],
    }
  },
  created() {
    this.getCategoriesTemplates();
  },
  methods: {
    save() {
      if (!this.isLoading) {
        this.isLoading = true
        RepositoryFactory.create('teamsUser').createDefaultTeam({
          name: this.name,
          color: this.color,
          categories: this.selectedCategories
        }).then(response => {
          this.$store.dispatch('redirect/deleteForceRedirect')
          const user = response.data.data
          this.$store.dispatch('auth/updateAuth', {user})
          this.$router.replace('/')
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.$i18n.t('Success'),
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: response.data.message,
                  },
                })
              })
        }).catch(error => {
          this.isLoading = false
          if (error.data && error.data.errors) {
            this.$refs.refFormObserver.setErrors(error.data.errors)
          }
        })
      }
    },
    logout() {
      this.authRepository().logout()
          .then(response => {
            this.$store.dispatch('redirect/deleteForceRedirect')
            this.$store.dispatch('auth/logout')
            this.$router.replace({name: 'login'})
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: this.$i18n.t('Success'),
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: this.$i18n.t('You have successfully logged out .'),
                    },
                  })
                })
          })
    },
    getCategoriesTemplates() {
      this.utilsAppRepository().getCategoriesTemplates().then(res => {
        const response = res.data
        response.data.forEach(item => {
          this.categoriesTemplates.push(item)
        })
      })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.auth-wrapper.auth-v1 .auth-inner {
  max-width: 500px !important;
}

.auth-wrapper.auth-v1 {
  overflow: scroll !important;
}

</style>
