<template>
  <div class="mb-3 mt-2 select-color">
    <div class="d-flex justify-content-between">
      <div
          v-for="color in colors"
          :key="color"
          class="color"
          :class="color === selectedColor? 'selected': ''"
          :style="{background:color}"
          @click="changeColor(color)"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    selectedColor: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      colors: [
        '#E2E8F0',
        '#FEB2B2',
        '#FAF089',
        '#9AE6B4',
        '#90CDF4',
        '#A3BFFA',
        '#D6BCFA',
        '#FBB6CE',
        '#7EDCE2',
      ]
    }
  },
  methods: {
    changeColor(color) {
      this.$emit(`update:selectedColor`, color)
    },
  }
}
</script>
<style>
.select-color .color {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.select-color .color.selected {
  border: 2px solid #000000;
}

.select-color .color:hover:not(.selected) {
  border: 2px solid #ffffff;
}
</style>
